import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react'

import { Container } from './styled'

const NextPageButton: React.FC = (props) => {
  const router = useRouter();

  const order = ['/','/about','/resume','/blog','/contact']
  const currentPage = router.pathname

  const currentIdx = order.indexOf(currentPage)
  const nextPagePath = order[currentIdx + 1] ?? order[0]
  const prevPagePath = order[currentIdx - 1] ?? order[order.length -1]

  return (
  <Container>
    <Link href={nextPagePath}>
        <a>
            <div>
              Proxima Página
              <i className="lnr lnr-chevron-right" />
            </div>
        </a>
    </Link>

    
    <Link href={prevPagePath}>
        <a>
          <div>
            Página Anterior
            <i className="lnr lnr-chevron-left" />
          </div>
        </a>
    </Link>
  </Container>)
}

export { NextPageButton }
