import React, { useContext, useEffect, useMemo } from "react"
import debounce from 'lodash.debounce';

import { Button } from './styled'

import { MenuContext } from '../../context/Menu'

const HamburguerButton : React.FC = () => {
    const [active, setActive] = useContext(MenuContext)

    return (
        <Button onClick={() => setActive(!active)} className={ active ? "open": "" }>
            <span></span>
            <span></span>
            <span></span>
        </Button>
    )
}

export { HamburguerButton }