import styled from 'styled-components';
import Image from 'next/image'

import { NavListContainer } from '../NavList'

export const Container = styled.div` 
`;

export const Wrapper = styled.header<{ active: boolean }>`
    position: fixed;
    width: ${props => props.active ? '100%' : '0' };
    top: 0;
    right: 0;
    height: 100vh;
    width: ${props => props.active ? '100%' : '0' };
    transition: all .45s ${props => props.active ? 'ease-out' : 'ease-in' };
    transform: ${props => props.active ? `translateX(0%)` : `translateX(100%)`};
    max-width: 330px;
    z-index: 8;
    background: #444;
    padding: 30px 30px 10px;
    overflow: hidden;

    @media only screen and (min-width: 1024px) {  
        position: initial;
        width: 100%;
        padding: 70px 30px 45px;
        max-width: 380px;
        height: initial;
        overflow-y: hidden;
        top: initial;
        right: initial;
        transform: initial;
        border-radius: 35px;

        &:hover{
            overflow-y: scroll
        }

        .navprofile-menu{
            display: none;
        } 
    }
`

export const Header = styled.div`
    /* @media only screen and (min-width: 1024px) {
        max-width: 290px;
    } */
`

export const HeaderContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 auto;
`

export const HeaderContainerPhoto = styled.div`
    position: relative;

    .bg{
        &:after{
            border-radius: 100%;
            content: '';
            position: absolute;
            left: 10px;
            top: 2px;
            opacity: .3;
            height: calc(100% + 0px);
            width: calc(100% + 0px);
            background-size: 6px 6px;
            background-image: -webkit-repeating-radial-gradient(center center,#fff,#fff 1px,transparent 0px,transparent 100%);
        }
        &:before{
            border-radius: 100%;
            content: '';
            position: absolute;
            left: 2px;
            top: -4px;
            height: 100%;
            width: 100%;
            background-color: ${props => props.theme.COLORS.WHITE}
        }
    }
`

export const HeaderImage = styled(Image)`
    width: 130px;
    position: relative;
    margin: 0 auto 30px;
    z-index: 1;
    max-width: 100%;
    background-color: #fff;
    border: 3px solid #fff;
    border-radius: 100%;

    @media only screen and (max-width: 1024px) {
        max-width: 130px;
        margin-bottom: 25px;   
    }
`

export const HeaderTitles = styled.div`
    margin-top: 20px;
    text-align: center;

    h2 {
        @media only screen and (max-width: 1024px) {   
            font-size: 30px;
            margin-bottom: 10px;
        }

        font-size: 36px;
        font-weight: 600;
        color: ${props => props.theme.COLORS.WHITE};
        margin: 5px 0 7px;
        line-height: 1.2em;
    }

    h4{
        @media only screen and (max-width: 1024px) {   
            font-size: 17px;
        }

        font-size: 18px;
        font-weight: 300;
        color: #bbb;
        margin: 5px 0;
        line-height: 1.2em;
    }
`

export const Menu = styled(NavListContainer)`
    margin: 30px 0;
`

export const SocialLinks = styled.div`
    margin: 20px 0;

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    ul li a {
        color: #fff;
        border-radius: 20px;
        font-size: 18px;
        padding: 0;
        height: 30px;
        width: 30px;
        display: block;
        line-height: 30px;
        text-align: center;
        opacity: .9;
    }
`

export const HeaderButtons = styled.div`
    margin-top: 50px;
`

export const DownloadResumeButton = styled.button`
    user-select: none;
    display: block;
    position: relative;
    padding: 0.8em 2.1em;
    margin: auto;
    font-size: 1em;
    line-height: 1.2;
    border: 0;
    outline: 0;
    border: 2px solid ${props => props.theme.COLORS.PRIMARY};
    color: ${props => props.theme.COLORS.WHITE};
    text-shadow: none;
    border-radius: 30px;
    font-family: poppins,Helvetica,sans-serif;
    transition: all .3s ease-in-out;
    box-shadow: 0 10px 10px -8px rgb(0 0 0 / 30%);
    background-color: transparent;
    color: ${props => props.theme.COLORS.WHITE};
    border-color: ${props => props.theme.COLORS.WHITE};

    &:hover{
        background-color: #fff;
        color: ${props => props.theme.COLORS.PRIMARY};
        border-color: #fff;
    }
`