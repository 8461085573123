import styled from 'styled-components';

export const Container = styled.ul`
    display: flex;
    justify-content: center;
    list-style: none;
    flex-flow: column nowrap;
    padding: 0;

    li{
        display: flex;
        justify-content: center;
    }

    a {
        text-decoration: none;
        color: #fff;
        width: 80%;
        justify-content: center;
        display: flex;
    }

    .menu-text{
        display: block;
        color: #fff;
        padding: 12px 0;
        line-height: 21px;
        opacity: .7;
    }    
    .menu-icon{
        display: none;
    }

    &.active-route-home{
        li:nth-child(1) .menu-text{
            opacity: 1
        }
        li:nth-child(1) .menu-icon{
            color: ${props => props.theme.COLORS.PRIMARY}
        }
    }
    &.active-route-about{
        li:nth-child(2) .menu-text{
            opacity: 1
        }
        li:nth-child(2) .menu-icon{
            color: ${props => props.theme.COLORS.PRIMARY}
        }
    }
    &.active-route-resume{
        li:nth-child(3) .menu-text{
            opacity: 1
        }
        li:nth-child(3) .menu-icon{
            color: ${props => props.theme.COLORS.PRIMARY}
        }
    }
    &.active-route-blog{
        li:nth-child(4) .menu-text{
            opacity: 1
        }
        li:nth-child(4) .menu-icon{
            color: ${props => props.theme.COLORS.PRIMARY}
        }
    }
    &.active-route-contact{
        li:nth-child(5) .menu-text{
            opacity: 1
        }
        li:nth-child(5) .menu-icon{
            color: ${props => props.theme.COLORS.PRIMARY}
        }
    }
`;
