import { createGlobalStyle } from "styled-components";
// const bootstrapGrid = require('bootstrap/dist/css/bootstrap-grid.css')
// console.log(bootstrapGrid)

export const GlobalStyle = createGlobalStyle`
    html, body{
        padding: 0;
        margin: 0;
        border: 0;
        font-family: poppins,Helvetica,sans-serif;
        background-color: #222;
    }

    * {
        box-sizing: border-box;
    }

    h1, h2, h3, h4, h5, h6 {
        color: #eee;
        font-weight: 600;
        margin: 0;
    }
`

// @import url('../node_modules/bootstrap/dist/css/bootstrap-grid.css');

