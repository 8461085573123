import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    right: 10px;
    bottom: 20px;
    z-index: 6;
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;
    width: 48px;
    height: 120px;
    border: 2px solid ${props => props.theme.COLORS.PRIMARY};
    border-radius: 25px;
    background-color: #000;

    @media only screen and (min-width: 1024px) {
        display: none;
    }

    a {
        color: transparent;
        font-size: 0px;
        flex: 1 1 100%;
        display: block;
        position: relative;
        /* opacity: 0.5; */
        transition: .25s ease-in-out;
        
        :hover{
            opacity: 1;
            
            i {
                font-weight: 900;
                color: ${props => props.theme.COLORS.PRIMARY};
            }
        }
        
        i {
            transition: .25s ease-in-out;
            color: ${props => props.theme.COLORS.WHITE};
            font-size: 22px;
        }

        div{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
`
