import React from 'react'
import Link from 'next/link'

import {Container} from './styled'
import { useRouter } from 'next/router';

interface IProps {
    className?: string
}
const NavListContainer : React.FC<IProps> = (props) => {
    const router = useRouter();
    const { className = '' } = props
    const activeRoute =  router.pathname.replace('/','')
    const activeRouteClass = !!activeRoute ? `active-route-${activeRoute}` : 'active-route-home' 

    const computedClassName = `${className} ${activeRouteClass}`
    return (
    <Container {...props} className={computedClassName.trim()}>
        <li>
            <Link href="/" >
                <a>
                    <span className="menu-icon lnr lnr-home"></span>
                    <span className="menu-text">Home</span>
                </a>
            </Link>
        </li>
        <li>
            <Link href="/about">
                <a>
                    <span className="menu-icon lnr lnr-user"></span>
                    <span className="menu-text">About Me</span>
                </a>
            </Link>
        </li>
        <li>
            <Link href="/resume">
                <a>
                    <span className="menu-icon lnr lnr-graduation-hat"></span>
                    <span className="menu-text">Resume</span>
                </a>
            </Link>
        </li>
        <li>
            <Link href="/blog">
                <a>
                    <span className="menu-icon lnr lnr-book"></span>
                    <span className="menu-text">Blog</span>
                </a>
            </Link>
        </li>
        <li>
            <Link href="/contact">
                <a>
                    <span className="menu-icon lnr lnr-envelope"></span>
                    <span className="menu-text">Contact</span>
                </a>
            </Link>
        </li>
  </Container>)
}

export { NavListContainer }