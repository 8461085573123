import type { AppProps } from 'next/app'
import styled, { ThemeProvider } from 'styled-components'
import { theme } from '../theme'
import { GlobalStyle } from '../theme/globals'
import { motion } from 'framer-motion'
import './global.css'
import { MenuContext } from '../client/context/Menu'
import { NavProfile } from '../client/containers/NavProfile'
import { HamburguerButton } from '../client/components/HamburguerButton'
import { NextPageButton } from '../client/components/NextPageButton'
import Particles, { IParticlesProps, IParticlesState } from 'react-tsparticles'
import { useEffect, useState } from 'react'
import { Router, useRouter } from 'next/router'
import { NavListContainer } from '~/client/containers/NavList'

export const WrapperPageParticles = styled.div<{ active: boolean }>`
    height: 100vh;
    width: 100vw;
    z-index: 0;
    position: fixed;

    canvas{
        pointer-events: ${props => props.active ? 'initial' : 'none' } !important;
    }
`


function SafeHydrate({ children }: any) {
  return (
    <div suppressHydrationWarning>
      {typeof window === 'undefined' ? null : children}
    </div>
  )
}

function MyApp({ Component, pageProps, router }: AppProps) {
  const [active, setActive] = useState(false);

  Router.events.on("routeChangeStart", (ev) => {
    setActive(false)
  })

  const [clickedTime, setClickedTime] = useState(0)
  
  const cantClick = clickedTime < 20
  const onWrapperClick = () => {
    console.log('clicked')
    if(cantClick) setClickedTime(clickedTime+1)
  }

  useEffect(() => {
    if(active){
      window.document.body.classList.add('active-menu')
    } else {
      window.document.body.classList.remove('active-menu')
    }
  },[active])

  return (
    <div className="root">
    <SafeHydrate>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <MenuContext.Provider value={[active, setActive]}>
            <WrapperPageParticles active={cantClick} onClick={onWrapperClick}>
              <Particles className="particles-wrapper" url="/particlesjs-config.json" />
            </WrapperPageParticles>
          <div className="page-content">
            <NavListContainer className="nav-container-root"/>
            <HamburguerButton />

            <NavProfile />

            <NextPageButton />
            <div className="animated-wrapper">
              <motion.div key={router.route} initial="pageInitial" animate="pageAnimate"
                variants={{
                  pageInitial: {
                    transform: `translateX(-100%)`,
                    transitionDuration: ".2s",
                    transitionTimingFunction: 'ease-out'
                  },
                  pageAnimate: {
                    transform: `translateX(0%)`,
                    transitionTimingFunction: 'ease-out',
                    transitionDuration: ".2s"
                  }
                }}
                className="animated-container">
                <Component {...pageProps}/>
              </motion.div>

            </div>
          </div>
        </MenuContext.Provider>
      </ThemeProvider>
    </SafeHydrate>
    </div>
  )
}

export default MyApp
