import React from 'react'
import Image from 'next/image'
import { Header, Wrapper, HeaderContent, HeaderContainerPhoto, HeaderImage, HeaderTitles, Menu, SocialLinks, HeaderButtons, DownloadResumeButton } from './styled'
import { MenuContext } from '../../context/Menu'

const NavProfile: React.FC = () => {

  return (
    <MenuContext.Consumer>{([value]) => (
      <Wrapper active={value}>
      <Header>
        <HeaderContent>
          <HeaderContainerPhoto>
            <div className="bg">
              <HeaderImage src="/images/avatar.png" width={130} height={130} layout="intrinsic" alt="Alex Smith" />
            </div>
          </HeaderContainerPhoto>
          <HeaderTitles>
            <h2>Kelvin Silva</h2>
            <h4>Fullstack Developer</h4>
          </HeaderTitles>
        </HeaderContent>

        <Menu className="navprofile-menu" />

        <SocialLinks>
          <ul>
            <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
          </ul>
        </SocialLinks>

        <HeaderButtons>
          <DownloadResumeButton>Download CV</DownloadResumeButton>
        </HeaderButtons>
      </Header>
      </Wrapper>
    )}</MenuContext.Consumer>
  )
}

export { NavProfile }