import styled from 'styled-components';

export const Button = styled.button`
    position: fixed;
    display: block;
    width: 48px;
    height: 48px;
    line-height: 46px;
    text-align: center;
    background-color: #5050f5;
    right: 10px;
    font-size: 19px;
    top: 10px;
    border-radius: 30px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 10;
    border-color: transparent;

    @media only screen and (min-width: 1024px) {
        display: none;
    }
        

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #fff;
        border-radius: 5px;
        opacity: 1;
        left: 50%;
        transition: .25s ease-in-out;
    }

    span:nth-child(1) {
        top: 12px;
        transform: translate(-50%, -50%) rotate(0deg) scaleX(0.6);
        transform-origin: center;

    }

    span:nth-child(2) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(0deg) scaleX(0.6);
        transform-origin: center;
    }

    span:nth-child(3) {
        top: 32px;
        transform: translate(-50%, -50%) rotate(0deg) scaleX(0.6);
        transform-origin: center;
    }

    &.open span:nth-child(1) {
        width: 100%;
        top: 50%;
        left: calc(50%);
        transform: translate(-50%, -50%) rotate(45deg) scaleX(0.6);
        transform-origin: center;
    }

    &.open span:nth-child(2) {
        opacity: 0;
    }

    &.open span:nth-child(3) {
        width: 100%;
        top: 50%;
        left: calc(50%);
        transform: translate(-50%, -50%) rotate(-45deg) scaleX(0.6);
        transform-origin: center;
    }
`;
